import ReactTooltip from "react-tooltip";
import { useState } from "react";
import { getBasePath } from "@/shared/config";

interface CategoryBadge {
  status?: string | null;
  heritageStatus?: string | null;
  size?: number;
}

const CategoryBadge = ({ status, heritageStatus, size = 5 }: CategoryBadge) => {
  let tooltipId = Math.floor(Math.random() * Date.now());
  const badgeClasses = `h-${size} w-${size}`;
  const [tooltip, showTooltip] = useState(true);

  const image = getStatusImage(status, heritageStatus);
  if (status && image) {
    return (
      <>
        {tooltip && (
          <ReactTooltip
            effect="solid"
            id={tooltipId.toString()}
            backgroundColor="black"
            className="flat-color"
          />
        )}
        <div
          data-for={tooltipId.toString()}
          data-tip={returnTooltipMessage(status, heritageStatus)}
        >
          <img src={image} className={badgeClasses} />
        </div>
      </>
    );
  } else {
    return null;
  }
};

const getStatusImage = (
  status?: string | null,
  heritageStatus?: string | null
) => {
  switch (status) {
    case "curated":
    case "playground":
    case "factory":
      return `${getBasePath()}/badges/${status}.svg`;
    case "presents":
      if (heritageStatus === "playground")
        return `${getBasePath()}/badges/playground.svg`;
      if (heritageStatus === "factory")
        return `${getBasePath()}/badges/factory.svg`;
      // TODO: real present badge here
      return "";
    default:
      return "";
  }
};

const returnTooltipMessage = (
  status: string,
  heritageStatus?: string | null
) => {
  switch (status) {
    case "curated":
      return "Art Blocks Curated";
    case "presents":
      if (heritageStatus === "playground") return "Art Blocks Playground";
      if (heritageStatus === "factory") return "Art Blocks Factory";
      return "Art Blocks Presents";
    case "playground":
      return "Art Blocks Playground";
    case "factory":
      return "Art Blocks Factory";
    default:
      return null;
  }
};

export default CategoryBadge;
