import { bpsToPercent } from "@/shared/helpers";
import { Eth } from "client/components/icons/eth";

export const AssetSummary = ({
  imageUrl,
  name,
  artistName,
  royaltyBps,
  stats,
}: {
  imageUrl?: string;
  name?: string;
  artistName?: string;
  royaltyBps?: number;
  stats?: {
    lastPrice?: number;
    collectionFloor?: number;
    topTraitFloor?: number;
    topBidPrice?: number;
  };
}) => {
  return (
    <div className="mt-4 flex px-8 border-b dark:border-neutral-700 pb-8">
      <img src={imageUrl} className="bg-neutral-500 w-44 block shadow-lg" />
      <div className="px-8 flex-grow">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="text-sm">
              <p className="text-base">{name}</p>
              <p>{artistName}</p>
              <div className="mt-2 flex flex-col gap-2">
                {royaltyBps ? (
                  <p className="text-sm text-neutral-600 dark:text-neutral-400">
                    {`Royalties: ${bpsToPercent(royaltyBps).toFixed(1)}%`}
                  </p>
                ) : null}

                {stats?.lastPrice ? (
                  <>
                    <p className="text-sm flex items-center text-neutral-600 dark:text-neutral-400">
                      Purchased For:{" "}
                      <Eth size={12} className="ml-1 flex-shrink-0" />
                      {stats.lastPrice}
                    </p>
                  </>
                ) : null}

                {stats?.collectionFloor ? (
                  <>
                    <p className="text-sm flex items-center text-neutral-500 dark:text-neutral-400">
                      Collection Floor:{" "}
                      <Eth size={12} className="ml-1 flex-shrink-0" />
                      {stats.collectionFloor.toFixed(2)}
                    </p>
                  </>
                ) : null}
                {stats?.topTraitFloor ? (
                  <>
                    <p className="text-sm flex items-center text-neutral-500 dark:text-neutral-400">
                      Top Trait Floor:{" "}
                      <Eth size={12} className="ml-1 flex-shrink-0" />
                      {stats.topTraitFloor.toFixed(2)}
                    </p>
                  </>
                ) : null}
                {stats?.topBidPrice ? (
                  <>
                    <p className="text-sm flex items-center text-neutral-500 dark:text-neutral-400">
                      Top Offer Price:{" "}
                      <Eth size={12} className="ml-1 flex-shrink-0" />
                      {stats.topBidPrice.toFixed(2)}
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetSummary;
