import analytics from "client/lib/analytics";
import { XLogo } from "../icons/x-logo";

const XShareButton = ({
  title,
  socialMsg,
  location,
}: {
  title: string;
  socialMsg: string;
  location: string;
}) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        analytics.track("Share Button Clicked", {
          location: location,
          platform: "twitter",
        });
      }}
      className="dark:bg-white dark:text-black dark:hover:text-black dark:hover:bg-neutral-200 rounded-xl hover:bg-neutral-700 transition mt-4 bg-neutral-900 text-white flex items-center justify-center p-4"
      href={`https://x.com/intent/post?text=${encodeURIComponent(socialMsg)}`}
    >
      <p className="flex items-center gap-3">
        <XLogo className="w-5 h-5" />
        {title}
      </p>
    </a>
  );
};

export default XShareButton;
