import { getBasePath } from "@/shared/config";
import { Loader } from "client/components/icons/loader";
import { OpenSea } from "client/components/icons/opensea";
import { Market, getMarketName } from "shared/marketplaces";

export const ApprovePending = ({ market }: { market?: Market }) => {
  const renderIcon = () => {
    if (market === Market.Opensea) {
      return <OpenSea className="h-10 w-10" />;
    } else if (market === Market.Sansa) {
      return (
        <img className="h-10 w-10" src={`${getBasePath()}/market-icon.svg`} />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="border-t dark:border-neutral-700 px-4 py-6">
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4 divide divide-y-neutral-200">
              {renderIcon()}
              <p className="text-lg">{market && getMarketName(market)}</p>
            </div>
            <div className="flex items-center gap-1 py-1 bg-neutral-200 dark:bg-neutral-800 dark:text-white px-1 rounded-md pr-2">
              <Loader
                size={22}
                className="animate-spin duration-300 text-neutral-900 dark:text-white"
              />
              <p className="text-sm">Awaiting Approval</p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex flex-col gap-1">
            <p className="text-sm mx-auto text-neutral-500 text-center pt-2">
              You will be prompted to approve access to this item in your wallet
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovePending;
