export const sleep = async (ms: number) => {
  return new Promise((res) => {
    setTimeout(res, ms);
  });
};

export const isValidMongoId = (val: string) => {
  return val && val?.match(/^[0-9a-fA-F]{24}$/);
};

export const formatCurrencyUsdDisplay = (x: number) => {
  if (!x) return "-";
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Convert a bps percent value to a decimal percent value
 * ex: 750 bps = .075
 */
export const bpsToDecimalPercent = (bps: number) => {
  if (!bps) return 0;
  return bps / 10000;
};

/**
 * Convert a bps percent value to a percent value to display
 * ex: 750 bps = 7.5
 */
export const bpsToPercent = (bps: number) => {
  if (!bps) return 0;
  return bpsToDecimalPercent(bps) * 100;
};
