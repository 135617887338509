import { Loader } from "@/client/components/icons/loader";
import { OpenSea } from "@/client/components/icons/opensea";
import { Market, getMarketName } from "@/shared/marketplaces";

import { AssetSummarySmall } from "./AssetSummarySmall";
import { getBasePath } from "@/shared/config";

export const SignPending = ({
  market,
  marketPrice,
  marketPriceCurrencySymbol = "ETH",
  imageUrl,
  name,
  artistName,
}: {
  market?: Market;
  marketPrice?: number;
  marketPriceCurrencySymbol?: string;
  imageUrl?: string;
  name?: string;
  artistName?: string;
}) => {
  const renderIcon = () => {
    if (market === Market.Opensea) {
      return <OpenSea className="h-10 w-10" />;
    } else if (market === Market.Sansa) {
      return (
        <img className="h-10 w-10" src={`${getBasePath()}/market-icon.svg`} />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <AssetSummarySmall
        name={name}
        imageUrl={imageUrl}
        artistName={artistName}
        bidAmount={marketPrice}
        bidAmountCurrencySymbol={marketPriceCurrencySymbol}
      />
      <div className="border-t dark:border-neutral-700 px-4 py-6">
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4 divide divide-y-neutral-200">
              {renderIcon()}
              <p className="text-lg">{market && getMarketName(market)}</p>
            </div>
            <div className="flex items-center gap-1 py-1 dark:bg-neutral-800 bg-neutral-200 px-1 rounded-md pr-2">
              <Loader
                size={22}
                className="animate-spin duration-300 text-neutral-900 dark:text-white"
              />
              <p className="text-sm">Awaiting Transaction</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignPending;
