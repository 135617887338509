import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const XLogo = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_173_547)">
        <path d="M13.5222 10.6946L19.4785 3.5H18.0671L12.8952 9.74701L8.76437 3.5H4L10.2466 12.9466L4 20.4913H5.41155L10.8732 13.8942L15.2356 20.4913H20L13.5218 10.6946H13.5222ZM11.5889 13.0298L10.956 12.0891L5.92015 4.60416H8.0882L12.1522 10.6448L12.7851 11.5854L18.0677 19.4373H15.8997L11.5889 13.0302V13.0298Z" />
      </g>
      <defs>
        <clipPath id="clip0_173_547">
          <rect width="16" height="17" transform="translate(4 3.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
