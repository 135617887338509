import { Loader as LoaderIcon } from "../icons/loader";

export const Loading = ({
  message = "Loading...",
  size = 64,
}: {
  message?: string;
  size?: number;
}) => {
  return (
    <div className="flex flex-col items-center">
      <LoaderIcon
        size={size}
        className="duration-300 animate-spin dark:text-neutral-300 text-neutral-500"
      />{" "}
      <p className="text-neutral-500 dark:text-neutral-300 mt-4">{message}</p>
    </div>
  );
};

export default Loading;
