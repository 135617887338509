export const EasyImage = ({
  imageUrl,
  className,
  size = 32,
}: {
  imageUrl: string;
  className?: string;
  size?: number;
}) => {
  const safeUrl = imageUrl ? encodeURI(imageUrl) : undefined;
  return (
    <div
      className={`rounded-md flex-shrink-0 overflow-hidden ${className}`}
      style={{ height: size, width: size }}
    >
      <div
        className={`bg-cover bg-center bg-neutral-100 w-full h-full`}
        style={{
          backgroundImage: `url(${safeUrl})`,
        }}
      ></div>
    </div>
  );
};

export default EasyImage;
