import { CurrencyIcon } from "@/client/components/common/CurrencyIcon";
import { EasyImage } from "@/client/components/common/EasyImage";
import { getRelativeTime } from "@/client/lib/helpers";

export const AssetSummarySmall = ({
  imageUrl,
  name,
  artistName,
  bidAmountCurrencySymbol,
  bidAmount,
  expiration,
}: {
  imageUrl?: string;
  name?: string;
  artistName?: string;
  bidAmountCurrencySymbol?: string;
  bidAmount?: number;
  expiration?: Date;
}) => {
  return (
    <div className="mx-6">
      <div className="flex items-center justify-between py-4 border-b dark:border-neutral-800">
        <div className="flex items-center gap-4">
          <EasyImage size={88} imageUrl={imageUrl ? imageUrl : ""} />
          <div className="flex items-center">
            <div>
              <p className="text-lg">{name}</p>
              <p className="text-sm text-neutral-500 dark:text-neutral-400">
                {artistName}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-end text-lg">
            <CurrencyIcon size={18} currencySymbol={bidAmountCurrencySymbol} />{" "}
            {bidAmount}
          </div>
          <div>
            {expiration ? (
              <p className="text-sm text-neutral-500 dark:text-neutral-400">
                {/* Expires {getRelativeTime(new Date(expiration * 1000))} */}
                Expires {getRelativeTime(expiration)}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
