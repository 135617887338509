import { Eth } from "../icons/eth";
import { Weth } from "../icons/weth";

export const CurrencyIcon = ({
  currencySymbol,
  size,
  className,
}: {
  currencySymbol?: string;
  size?: number;
  className?: string;
}) => {
  const cleanSymbol = currencySymbol?.toLowerCase();
  switch (cleanSymbol) {
    case "eth":
      return (
        <Eth
          size={size}
          className={className + " text-neutral-500 dark:text-neutral-300"}
        />
      );
    case "weth":
      return <Weth size={size} className={className} />;
  }
  return (
    <div
      style={{ width: size, height: size }}
      className={`rounded-full text-white text-center bg-stone-400 block flex-shrink-0`}
    >
      <p className="text-xs">
        {cleanSymbol?.length ? cleanSymbol[0]?.toUpperCase() : `?`}
      </p>
    </div>
  );
};
